import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom"
import { POST_GET_AGENT_WITHDRAWAL_REQUEST } from '../../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { getToken, uct_local, amountFormat } from '../../../helpers/Helper';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";


const WithdrawalRequestList = props => {
    let history = useHistory();
    const [user_id, SetUserId] = useState(0);

    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = (status = "") => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_AGENT_WITHDRAWAL_REQUEST,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [6] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "withdrawalRequestId" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {uct_local(data.createdAt,'DD, MMM YYYY')}
                            </>, td)
                        }
                    },
                    { "data": "accoutHolderName" },
                    { "data": "bankAccountNo" },
                    { "data": "bankName" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {amountFormat(data.amount)}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.withdrawalRequestStatus === 'Pending' && <button type="button" className="border-0 badge badge-info pt-1 pb-1"  >{data.withdrawalRequestStatus}</button>}
                                {data.withdrawalRequestStatus === 'Completed' && <button type="button" className="border-0 badge badge-success pt-1 pb-1"  >{data.withdrawalRequestStatus}</button>}
                                {data.withdrawalRequestStatus === 'Cancelled' && <button type="button" className="border-0 badge badge-danger pt-1 pb-1" >{data.withdrawalRequestStatus}</button>}
                            </>, td)
                        }
                    }    
                ]
            });
        });
    }

  

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>QGINNY | Withdrawal Request List</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Withdrawal Request List</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <Link to="/agent/withdrawal/request" className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Send Withdrawal Request</Link>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 mb-5">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Date</td>
                                            <td>Accout Holder Name</td>
                                            <td>Account Number</td>
                                            <td>Bank Name</td>
                                            <td>Amount</td>
                                            <td>Status</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(WithdrawalRequestList)
