import React from 'react';
import { NavLink } from "react-router-dom";

import FeatherIcon from "feather-icons-react";
export default function Header() {

    return (
        <div className='left_nav_bar'>
            <ul className='sidebar-menu'>
                <li>
                    <NavLink exact to="/" className="has-arrow">
                        <i className="fa fa-tachometer" style={{ 'fontSize': '19px', 'marginTop': '5px', 'marginRight': '8px' }} aria-hidden="true"></i>
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/students" className="has-arrow">
                        <FeatherIcon icon="users" width="22" />
                        <span>Students </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/agent" className="has-arrow">
                        <FeatherIcon icon="users" width="22" />
                        <span>Agents </span>
                    </NavLink>
                </li>

                {/* <li>
                    <NavLink exact to="/properties" className="has-arrow">
                        <FeatherIcon icon="home" width="22" />
                        <span>Properties</span>
                    </NavLink>
                </li>
                <li>
                <NavLink exact to="/offers" className="has-arrow">
                <i className="fa fa-percent mr-2 ml-1" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                <span>Offers</span>
                </NavLink>
            </li> */}
                <li>
                    <NavLink exact to="/mock-tests" className="has-arrow">
                        <i className="fa fa-book mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Mock Test</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/payment" className="has-arrow">
                        <i className="fa fa-inr mr-2 ml-1 mt-2" aria-hidden="true" style={{ 'fontSize': '17px', 'marginTop': '5px' }}></i>
                        <span>Payment</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/withdrawal-request" className="has-arrow">
                        <i className="fa fa-inr" style={{ 'fontSize': '16px', 'marginTop': '7px', 'marginRight': '8px' }} aria-hidden="true"></i>
                        <span>Withdrawal Request</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/commission-report" className="has-arrow">
                        <i className="fa fa-percent" style={{ 'fontSize': '16px', 'marginTop': '7px', 'marginRight': '8px' }} aria-hidden="true"></i>
                        <span>Commission Report</span>
                    </NavLink>
                </li>
                <li>
                    <a href="#ProductSetting" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
                        <FeatherIcon icon="settings" width="22" />
                        <span style={{ 'marginTop': '2px' }}>Product Setting</span>
                    </a>
                    <ul className="collapse list-unstyled" id="ProductSetting">
                        <li><NavLink to="/language">Language</NavLink></li>
                        <li><NavLink to="/board">Board </NavLink></li>
                        <li><NavLink to="/class">Class </NavLink></li>
                        <li><NavLink to="/subject">Subject </NavLink></li>
                        <li><NavLink to="/chapter">Chapter </NavLink></li>
                    </ul>
                </li>
                <li>
                    <a href="#SettingSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
                        <FeatherIcon icon="settings" width="22" />
                        <span style={{ 'marginTop': '2px' }}>Setting</span>
                    </a>
                    <ul className="collapse list-unstyled" id="SettingSubmenu">
                        <li><NavLink to="/setting">Setting</NavLink></li>
                    </ul>
                </li>


            </ul>
        </div>
    );
}