import React,{ Component } from 'react';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

class Dashboard extends Component {
	constructor(props) {
        super(props);
    }

	render() {
		return (
        <>  
            <Helmet>
                <meta charSet="utf-8" />
                <title>QGINNY | Settings</title>
            </Helmet>
            <div className='row'>
                <div className='col-sm-12'>
                    <h5>Settings</h5>
                </div>
            </div>
            <div className='row mt-15'>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6 className='text-center'>Email Setting</h6>
                            <div className='mt-4 text-right'><Link to="/setting/email" style={{'color' : '#233b90'}}>Configure <i className="fa fa-angle-right" aria-hidden="true"></i></Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6 className='text-center'>SMS Setting</h6>
                            <div className='mt-4 text-right'><Link to="/setting/sms-setting" style={{'color' : '#233b90'}}>Configure <i className="fa fa-angle-right" aria-hidden="true"></i></Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6 className='text-center'>Ccavenue Setting</h6>
                            <div className='mt-4 text-right'><Link to="/setting/ccavenue-setting" style={{'color' : '#233b90'}}>Configure <i className="fa fa-angle-right" aria-hidden="true"></i></Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6 className='text-center'>Commission Setting</h6>
                            <div className='mt-4 text-right'><Link to="/setting/commission-setting" style={{'color' : '#233b90'}}>Configure <i className="fa fa-angle-right" aria-hidden="true"></i></Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6 className='text-center'>Common Setting</h6>
                            <div className='mt-4 text-right'><Link to="/setting/api-log" style={{'color' : '#233b90'}}>Configure <i className="fa fa-angle-right" aria-hidden="true"></i></Link></div>
                        </div>
                    </div>
                </div>
               
            </div>
        </>    
    );
}
}

export default Dashboard;
