import React, { useState } from 'react';

// component
import Header from '../component/header';
import AdminSideNavbar from './AdminSideNavbar';

function AdminLayout({ children }) {
    window.scrollTo(0, 0);
    const [MenuHide, setMenuHide] = useState('');

    const handleMenu = () => {
        if(MenuHide){
            setMenuHide('');
        }else{
            setMenuHide('hide_menu');
        }
    }
   
    return (
        <>  
            <div className={MenuHide}>
                <Header onChangeHandleMenu={handleMenu} />
                <div className='main_body'>
                    <AdminSideNavbar/>
                    <div className='web_body pt-3 pb-3 pl-20 pr-20'>
                        <div className='container-fluid'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminLayout;
