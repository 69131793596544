import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post } from '../../../helpers/api_helper';
import Loader from '../../../component/Loader';
import { POST_AGENT_WITHDRAWAL_REQUEST, POST_GET_AGENT_DETAIL } from '../../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { getUserId } from '../../../helpers/Helper';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const UserRole = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({
        accoutHolderName: '',
        bankAccountNo: '',
        bankName: '',
        bankBranchName: '',
        bankIFSCCode: '',
        amount : ''
    });
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            var res_data = await post(POST_GET_AGENT_DETAIL, { 'userId': getUserId() }, {});
            if (res_data.status) {
                setInputs(res_data.data.detail);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
            setLoader(1);
        }
        get_detaile();
    }, [])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_AGENT_WITHDRAWAL_REQUEST, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/agent/withdrawal/");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }


    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>QGINNY | Bank</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-8'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>Bank Info</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='row'>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Accout Holder Name</label>
                                                <input name="accoutHolderName" value={form_inputs.accoutHolderName} onChange={handleInputChange} className="form-control" placeholder="Enter Your Accout Holder Name" type="text" />
                                                {simpleValidator.current.message('accout holder name', form_inputs.accoutHolderName, 'required')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Accout Number</label>
                                                <input name="bankAccountNo" value={form_inputs.bankAccountNo} onChange={handleInputChange} className="form-control" placeholder="Enter Your Accout Number" type="text" />
                                                {simpleValidator.current.message('accout number', form_inputs.bankAccountNo, 'required|numeric')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Bank Name</label>
                                                <input name="bankName" value={form_inputs.bankName} onChange={handleInputChange} className="form-control" placeholder="Enter Your Bank Name" type="text" />
                                                {simpleValidator.current.message('bank name', form_inputs.bankName, 'required')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Branch Name</label>
                                                <input name="bankBranchName" value={form_inputs.bankBranchName} onChange={handleInputChange} className="form-control" placeholder="Enter Your Branch Name" type="text" />
                                                {simpleValidator.current.message('branch name', form_inputs.bankBranchName, 'required')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>IFSC Code</label>
                                                <input name="bankIFSCCode" value={form_inputs.bankIFSCCode} onChange={handleInputChange} className="form-control" placeholder="Enter Your IFSC Code" type="text" />
                                                {simpleValidator.current.message('branch name', form_inputs.bankIFSCCode, 'required')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Amount</label>
                                                <input name="amount" value={form_inputs.amount} onChange={handleInputChange} className="form-control" placeholder="Enter Your Amount" type="text" />
                                                {simpleValidator.current.message('amount', form_inputs.amount, 'required|numeric')}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                    <Link to="/agent/withdrawal" className='btn btn-primary c-btn-danger pt-1 pb-1 pl-3 pr-3 ml-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(UserRole)
