import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post, del } from '../../helpers/api_helper';
import { answer_option } from '../../helpers/Helper';
import Loader from '../../component/Loader';
import UploadMCQ from '../../component/UploadMCQ';
import {
    POST_ADD_EDIT_MOCK_TESTS,
    POST_GET_MOCK_TESTS_DETAIL,
    POST_GET_MOCK_TESTS_FORM_OPTION,
    POST_GET_CLASS_BY_BOARD_FORM_OPTION,
    POST_GET_SUBJECT_BY_CLASS_FORM_OPTION,
    POST_GET_CHAPTER_BY_SUBJECT_FORM_OPTION,
    POST_GET_ALL_QUESTIONS,
    DELETE_QUESTIONS
} from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import MockTestsQuestionForm from './MockTestsQuestionForm';

const UserRole = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const [title, setTitle] = useState('Add Mock Test');

    const [languageList, setLanguageList] = useState([]);
    const [boardList, setBoardList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [chapterList, setChapterList] = useState([]);

    const [questionsList, setQuestions] = useState([]);
    const [QuestionId, setQuestionId] = useState(0);
    const [QuestionInfo, setQuestionInfo] = useState({});

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ 'mockTestsId': 0, 'languageId': '', 'boardId': '', 'classId': '', 'subjectId': '', 'chapterId': '','mockTestsType' : '', 'mockTestsSet' : '' ,'passMarks': '' });
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                setTitle('Edit Mock Test');
                var res_data = await post(POST_GET_MOCK_TESTS_DETAIL, { 'mockTestsId': props.match.params.id }, {});
                if (res_data.status) {
                    setInputs(res_data.data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
                get_all_questions();
            }
            get_form_option();
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])


    //get course form option
    const get_form_option = async () => {
        var res_data = await post(POST_GET_MOCK_TESTS_FORM_OPTION, { mockTestsId: props.match.params.id });
        if (res_data.status) {
            setLanguageList(res_data.data.language_list);
            setBoardList(res_data.data.board_list);
            setClassList(res_data.data.class_list);
            setSubjectList(res_data.data.subject_list);
            setChapterList(res_data.data.chapter_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    //get course form option
    const get_all_questions = async () => {
        var res_data = await post(POST_GET_ALL_QUESTIONS, { mockTestsId: props.match.params.id });
        if (res_data.status) {
            setQuestions(res_data.data.questions_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    //get course base board id 
    const get_class = async (boardId) => {
        var res_data = await post(POST_GET_CLASS_BY_BOARD_FORM_OPTION, { boardId: boardId });
        if (res_data.status) {
            setClassList(res_data.data.class_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    //get course base board id 
    const get_subject = async (classId) => {
        var res_data = await post(POST_GET_SUBJECT_BY_CLASS_FORM_OPTION, { classId: classId });
        if (res_data.status) {
            setSubjectList(res_data.data.class_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    //get course base board id 
    const get_chapter = async (subjectId) => {
        var res_data = await post(POST_GET_CHAPTER_BY_SUBJECT_FORM_OPTION, { subjectId: subjectId });
        if (res_data.status) {
            setChapterList(res_data.data.chapter_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_MOCK_TESTS, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/mock-tests/edit/" + res_data.data.id);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name == 'boardId') {
            form_inputs.classId = '';
            form_inputs.subjectId = '';
            form_inputs.chapterId = '';
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
            get_class(event.target.value);
            setSubjectList([]);
            setChapterList([]);

        } else if (event.target.name == 'classId') {
            form_inputs.subjectId = '';
            form_inputs.chapterId = '';
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
            get_subject(event.target.value);
            setChapterList([]);
        } else if (event.target.name == 'subjectId') {
            form_inputs.chapterId = '';
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
            get_chapter(event.target.value);
            setChapterList([]);

        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }


    // remove user role
    const remove_question = async () => {
        var res_data = await del(DELETE_QUESTIONS + "?questionId=" + QuestionId+"&mockTestsId="+props.match.params.id);
        if (res_data.status) {
            toast(res_data.message);
            get_all_questions();
        } else {
            toast.error(res_data.message);
        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>QGINNY | Mock Test</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>

                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>

                                    <div className="form-group mb-4 col-sm-3">
                                        <label className="form-label text-capitalize">Select Language</label>
                                        <select name="languageId" className="form-control" value={form_inputs.languageId} onChange={handleInputChange}>
                                            <option value="">Select Language</option>
                                            {Object.entries(languageList).map(([key, board]) => (
                                                <option value={board.languageId} key={key}>{board.languageName}</option>
                                            ))}
                                        </select>
                                        {simpleValidator.current.message('language', form_inputs.languageId, 'required')}
                                    </div>

                                    <div className="form-group mb-4 col-sm-3">
                                        <label className="form-label text-capitalize">Select Board</label>
                                        <select name="boardId" className="form-control" value={form_inputs.boardId} onChange={handleInputChange}>
                                            <option value="">Select Board</option>
                                            {Object.entries(boardList).map(([key, board]) => (
                                                <option value={board.boardId} key={key}>{board.boardName}</option>
                                            ))}
                                        </select>
                                        {simpleValidator.current.message('Board', form_inputs.boardId, 'required')}
                                    </div>

                                    <div className="form-group mb-4 col-sm-3">
                                        <label className="form-label text-capitalize">Select Class</label>
                                        <select name="classId" className="form-control" value={form_inputs.classId} onChange={handleInputChange}>
                                            <option value="">Select Class</option>
                                            {Object.entries(classList).map(([key, board]) => (
                                                <option value={board.classId} key={key}>{board.className}</option>
                                            ))}
                                        </select>
                                        {simpleValidator.current.message('class', form_inputs.classId, 'required')}
                                    </div>

                                    <div className="form-group mb-4 col-sm-3">
                                        <label className="form-label text-capitalize">Select Subject</label>
                                        <select name="subjectId" className="form-control" value={form_inputs.subjectId} onChange={handleInputChange}>
                                            <option value="">Select Subject</option>
                                            {Object.entries(subjectList).map(([key, board]) => (
                                                <option value={board.subjectId} key={key}>{board.subject}</option>
                                            ))}
                                        </select>
                                        {simpleValidator.current.message('subject', form_inputs.subjectId, 'required')}
                                    </div>

                                    <div className="form-group mb-4 col-sm-3">
                                        <label className="form-label text-capitalize">Select Chapter</label>
                                        <select name="chapterId" className="form-control" value={form_inputs.chapterId} onChange={handleInputChange}>
                                            <option value="">Select Chapter</option>
                                            {Object.entries(chapterList).map(([key, board]) => (
                                                <option value={board.chapterId} key={key}>{board.chapterName}</option>
                                            ))}
                                        </select>
                                        {simpleValidator.current.message('chapter', form_inputs.chapterId, 'required')}
                                    </div>
                                    <div className="form-group mb-4 col-sm-3">
                                        <label className="form-label text-capitalize">Select Set</label>
                                        <select name="mockTestsSet" className="form-control" value={form_inputs.mockTestsSet} onChange={handleInputChange}>
                                            <option value="">Select Set</option>
                                            <option value="Set 1">Set 1</option>
                                            <option value="Set 2">Set 2</option>
                                            <option value="Set 3">Set 3</option>
                                            <option value="Set 4">Set 4</option>
                                            <option value="Set 5">Set 5</option>
                                            <option value="Set 6">Set 6</option>
                                            <option value="Set 7">Set 7</option>
                                            <option value="Set 8">Set 8</option>
                                            <option value="Set 9">Set 9</option>
                                            <option value="Set 10">Set 10</option>
                                        </select>
                                    </div>
                                    <div className="form-group mb-4 col-sm-3">
                                        <label className="form-label text-capitalize">Type</label>
                                        <select name="mockTestsType" className="form-control" value={form_inputs.mockTestsType} onChange={handleInputChange}>
                                            <option value="">Select Type</option>
                                            <option value="MCQ">MCQ</option>
                                            <option value="CBQ">CBQ</option>
                                            <option value="HOTS">HOTS</option>
                                            <option value="ARQ">ARQ</option>
                                        </select>
                                        {simpleValidator.current.message('type', form_inputs.mockTestsType, 'required')}
                                    </div>
                                    <div className="form-group mb-4 col-sm-3">
                                        <label className="form-label text-capitalize">Select Based On</label>
                                        <select name="basedOn" className="form-control" value={form_inputs.basedOn} onChange={handleInputChange}>
                                            <option value="">Select based on</option>
                                            <option value="Based on NCERT Books">Based on NCERT Books</option>
                                            <option value="Based on Reference Books">Based on Reference Books</option>
                                        </select>
                                    </div>

                                    <div className="form-group mb-4 col-sm-3">
                                        <label className="form-label text-capitalize">Passing marks</label>
                                        <input name="passMarks" value={form_inputs.passMarks} onChange={handleInputChange} className="form-control" type="text" />
                                        {simpleValidator.current.message('Passing marks', form_inputs.passMarks, 'required')}
                                    </div>

                                    <div className="text-center mt-4 col-sm-12">
                                        <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                        <Link to="/mock-tests" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                    </div>
                                </div>
                            </form>

                            {props.match.params.id && <>
                                <hr />
                                <div className='row'>
                                    <div className='col-sm-6'></div>
                                    <div className='col-sm-6 text-right'>
                                        <button disabled={ButtonDisabled} type="button" data-toggle="modal" data-target="#UploadMCQModal" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'> Upload Questions</button>
                                        <button disabled={ButtonDisabled} onClick={() => { setQuestionInfo({}); }} type="button" data-toggle="modal" data-target="#QuestionAddEditModal" className='btn btn-danger c-btn-primary ml-3 pt-1 pb-1 pl-3 pr-3'>+ Add Questions</button>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-sm-12'>
                                        <div className='table-responsive'>
                                            <table className='table table-small'>
                                                <thead>
                                                    <tr>
                                                        <th width="20px">Sr.</th>
                                                        <th>Question</th>
                                                        <th width="100px">Marks</th>
                                                        <th width="120px">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.entries(questionsList).map(([key, value]) => (
                                                        <tr key={key}>
                                                            <td>{parseInt(key) + 1}</td>
                                                            <td><div className='p-margin-0' dangerouslySetInnerHTML={{ __html: value.question }}></div></td>
                                                            <td>{value.marks}</td>
                                                            <td>
                                                                <button type='button' className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { setQuestionInfo(value); }} data-toggle="modal" data-target="#QuestionAddEditModal" ><i className="fa fa-pencil" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                                                <button type='button' className="btn btn-sm btn-info pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { setQuestionInfo(value); }} data-toggle="modal" data-target="#QuestionViewModal" ><i className="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                                                <button type='button' className="btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { setQuestionId(value.questionsId); }} data-toggle="modal" data-target="#deleteModal" ><i className="fa fa-trash-o" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <UploadMCQ mockTestsId={props.match.params.id} call_back={() => {get_all_questions(); history.push("/mock-tests/edit/" + props.match.params.id); }} />
                                <MockTestsQuestionForm mockTestsId={props.match.params.id} question={QuestionInfo} call_back={() => {get_all_questions();}}/>
                            </>}
                        </div>
                    </div>
                </div>
            </div>

            {/* QuestionViewModal */}
            <div className="modal fade" id="QuestionViewModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title m-0" id="exampleModalLabel">View Question</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {QuestionInfo && <div>
                                <div className='row'>
                                    <div className='col-sm-12 mb-3'>
                                        <b>Q : </b> <span className='p-margin-0 ml-2' dangerouslySetInnerHTML={{ __html: QuestionInfo.question }}></span>
                                    </div>
                                    <div className='col-sm-12 mb-2'>
                                        <b>{`A)`} . </b> <span className='p-margin-0 ml-2' dangerouslySetInnerHTML={{ __html: QuestionInfo.option1 }}></span>
                                    </div>
                                    <div className='col-sm-12 mb-2'>
                                        <b>{`B)`} . </b> <span className='p-margin-0 ml-2' dangerouslySetInnerHTML={{ __html: QuestionInfo.option2 }}></span>
                                    </div>
                                    <div className='col-sm-12 mb-2'>
                                        <b>{`C)`} . </b> <span className='p-margin-0 ml-2' dangerouslySetInnerHTML={{ __html: QuestionInfo.option3 }}></span>
                                    </div>
                                    <div className='col-sm-12 mb-3'>
                                        <b>{`D)`} . </b> <span className='p-margin-0 ml-2' dangerouslySetInnerHTML={{ __html: QuestionInfo.option4 }}></span>
                                    </div>
                                    <div className='col-sm-12 mb-3'>
                                        <b>{`Answer)`} . </b> <span className='p-margin-0 ml-2'>{answer_option(QuestionInfo.answer)}</span>
                                    </div>
                                    <div className='col-sm-12 mb-2'>
                                        <b>{`Answer Description)`} . </b><br/> <span className='p-margin-0 ml-2' dangerouslySetInnerHTML={{ __html: QuestionInfo.answerExplanation }}></span>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            {/* deleteModal */}
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this record?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">No</button>
                            <button onClick={remove_question.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(UserRole)
