import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './layouts/App_route';
import UserRoute from './layouts/UserRoute';
import AgentRoute from './layouts/AgentRoute';

import { BrowserRouter } from 'react-router-dom';

import Full from './layouts/Full';

import AdminLayout from './layouts/AdminLayout';
import AgentLayout from './layouts/AgentLayout';


// pages
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login';
import Logout from "./pages/Auth/Logout";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ChangePassword from "./pages/Auth/ChangePassword";
import AgentSignUp from "./pages/Auth/AgentSignUp";

//Students
import StudentsList from "./pages/Students/StudentsList";

//agent
import AgentList from "./pages/Agent/AgentList";
import AgentForm from "./pages/Agent/AgentForm";

//payment
import PaymentList from "./pages/Payment/PaymentList";


// setting
import Setting from "./pages/Setting/Setting";
import EmailSetting from "./pages/Setting/EmailSetting";
import SmsSetting from "./pages/Setting/SmsSetting";
import CcavenueSetting from "./pages/Setting/CcavenueSetting";
import CommissionSetting from "./pages/Setting/CommissionSetting";
import ApiLogSetting from "./pages/Setting/ApiLogSetting";

//language
import LanguageList from "./pages/Language/LanguageList";
import LanguageForm from "./pages/Language/LanguageForm";

//board
import BoardList from "./pages/Board/BoardList";
import BoardForm from "./pages/Board/BoardForm";

//class
import ClassList from "./pages/Class/ClassList";
import ClassForm from "./pages/Class/ClassForm";

//Subject
import SubjectList from "./pages/Subject/SubjectList";
import SubjectForm from "./pages/Subject/SubjectForm";

//Chapter
import ChapterList from "./pages/Chapter/ChapterList";
import ChapterForm from "./pages/Chapter/ChapterForm";

//MockTests
import MockTestsList from "./pages/MockTests/MockTestsList";
import MockTestsForm from "./pages/MockTests/MockTestsForm";

//MockTests
import WithdrawalRequestList from "./pages/WithdrawalRequest/WithdrawalRequestList";

import CommissionRequestList from "./pages/Report/CommissionRequestList";


//agent module
import AgentDashboard from "./pages/Agent/Agent/Dashboard";
import AgentProfile from "./pages/Agent/Agent/Profile";
import AgentBank from "./pages/Agent/Agent/Bank";
import AgentWithdrawalRequestList from "./pages/Agent/Agent/WithdrawalRequestList";
import AgentWithdrawalRequest from "./pages/Agent/Agent/WithdrawalRequest";
import AgentCommissionReportList from "./pages/Agent/Agent/CommissionReportList";


function App() {
    return (
        <>
        <BrowserRouter>
            <Switch>
                <AppRoute exact path="/login" component={Login} layout={Full} />
                <AppRoute exact path="/logout" component={Logout} layout={Full} />
                <AppRoute exact path="/forgot-password" component={ForgetPassword} layout={Full} />
                <AppRoute exact path="/agent-sign-up" component={AgentSignUp} layout={Full} />
                
                <UserRoute exact path="/change-password" component={ChangePassword} layout={AdminLayout} />

                {/* Setting */}
                <UserRoute exact path="/students" component={StudentsList} layout={AdminLayout} />

                {/* agent */}
                <UserRoute exact path="/agent" component={AgentList} layout={AdminLayout} />
                <UserRoute exact path="/agent/add" component={AgentForm} layout={AdminLayout} />
                <UserRoute exact path="/agent/edit/:id" component={AgentForm} layout={AdminLayout} />

                {/* PaymentList */}
                <UserRoute exact path="/payment" component={PaymentList} layout={AdminLayout} />

                {/* Setting */}
                <UserRoute exact path="/setting" component={Setting} layout={AdminLayout} />
                <UserRoute exact path="/setting/email" component={EmailSetting} layout={AdminLayout} />
                <UserRoute exact path="/setting/api-log" component={ApiLogSetting} layout={AdminLayout} />
                <UserRoute exact path="/setting/sms-setting" component={SmsSetting} layout={AdminLayout} />
                <UserRoute exact path="/setting/ccavenue-setting" component={CcavenueSetting} layout={AdminLayout} />
                <UserRoute exact path="/setting/commission-setting" component={CommissionSetting} layout={AdminLayout} />

                {/* language */}
                <UserRoute exact path="/language" component={LanguageList} layout={AdminLayout} />
                <UserRoute exact path="/language/add" component={LanguageForm} layout={AdminLayout} />
                <UserRoute exact path="/language/edit/:id?" component={LanguageForm} layout={AdminLayout} />
                
                {/* board */}
                <UserRoute exact path="/board" component={BoardList} layout={AdminLayout} />
                <UserRoute exact path="/board/add" component={BoardForm} layout={AdminLayout} />
                <UserRoute exact path="/board/edit/:id?" component={BoardForm} layout={AdminLayout} />

                {/* class */}
                <UserRoute exact path="/class" component={ClassList} layout={AdminLayout} />
                <UserRoute exact path="/class/add" component={ClassForm} layout={AdminLayout} />
                <UserRoute exact path="/class/edit/:id?" component={ClassForm} layout={AdminLayout} />

                {/* subject */}
                <UserRoute exact path="/subject" component={SubjectList} layout={AdminLayout} />
                <UserRoute exact path="/subject/add" component={SubjectForm} layout={AdminLayout} />
                <UserRoute exact path="/subject/edit/:id?" component={SubjectForm} layout={AdminLayout} />
                
                {/* Chapter */}
                <UserRoute exact path="/chapter" component={ChapterList} layout={AdminLayout} />
                <UserRoute exact path="/chapter/add" component={ChapterForm} layout={AdminLayout} />
                <UserRoute exact path="/chapter/edit/:id?" component={ChapterForm} layout={AdminLayout} />
                
                {/* Chapter */}
                <UserRoute exact path="/mock-tests" component={MockTestsList} layout={AdminLayout} />
                <UserRoute exact path="/mock-tests/add" component={MockTestsForm} layout={AdminLayout} />
                <UserRoute exact path="/mock-tests/edit/:id?" component={MockTestsForm} layout={AdminLayout} />

                {/* Withdrawal Request */}
                <UserRoute exact path="/withdrawal-request" component={WithdrawalRequestList} layout={AdminLayout} />

                {/* commission Report */}
                <UserRoute exact path="/commission-report" component={CommissionRequestList} layout={AdminLayout} />


                {/* agent module */}
                <AgentRoute exact path="/agent/dashboard" component={AgentDashboard} layout={AgentLayout} />
                <AgentRoute exact path="/agent/change-password" component={ChangePassword} layout={AgentLayout} />
                <AgentRoute exact path="/agent/profile" component={AgentProfile} layout={AgentLayout} />
                <AgentRoute exact path="/agent/bank" component={AgentBank} layout={AgentLayout} />
                <AgentRoute exact path="/agent/withdrawal" component={AgentWithdrawalRequestList} layout={AgentLayout} />
                <AgentRoute exact path="/agent/withdrawal/request" component={AgentWithdrawalRequest} layout={AgentLayout} />
                <AgentRoute exact path="/agent/commission-report" component={AgentCommissionReportList} layout={AgentLayout} />

                <UserRoute exact path="/" component={Dashboard} layout={AdminLayout} />
            </Switch>
        </BrowserRouter> 
    </>
  );
}

export default App;
