
// auth
export const POST_LOGIN = "/auth/login"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"
export const POST_AGENT_SIGN_UP = "/auth/sign-up-agent"

export const GET_DASHBOARD_DATA = "/dashboard/dashboard"
export const POST_CHANGE_PASSWORD = "/dashboard/change-password"

//setting
export const GET_ALL_SETTING = "/setting/get-all-setting"
export const POST_UPDATE_SETTING = "/setting/update-setting"

//language
export const POST_ALL_LANGUAGE_LIST = process.env.REACT_APP_API_URL+"language/get-all-list"
export const DELETE_LANGUAGE = "/language/delete"
export const POST_UPDATE_LANGUAGE_STATUS = "/language/update-status"
export const POST_ADD_EDIT_LANGUAGE = "/language/add-edit"
export const POST_GET_LANGUAGE_DETAIL = "/language/detail"

//board
export const POST_ALL_BOARD_LIST = process.env.REACT_APP_API_URL+"board/get-all-list"
export const DELETE_BOARD = "/board/delete"
export const POST_UPDATE_BOARD_STATUS = "/board/update-status"
export const POST_ADD_EDIT_BOARD = "/board/add-edit"
export const POST_GET_BOARD_DETAIL = "/board/detail"

//class
export const POST_ALL_CLASS_LIST = process.env.REACT_APP_API_URL+"class/get-all-list"
export const DELETE_CLASS = "/class/delete"
export const POST_UPDATE_CLASS_STATUS = "/class/update-status"
export const POST_ADD_EDIT_CLASS = "/class/add-edit"
export const POST_GET_CLASS_DETAIL = "/class/detail"
export const POST_GET_CLASS_FORM_OPTION = "/class/get-form-option"

//subject
export const POST_ALL_SUBJECT_LIST = process.env.REACT_APP_API_URL+"subject/get-all-list"
export const DELETE_SUBJECT = "/subject/delete"
export const POST_UPDATE_SUBJECT_STATUS = "/subject/update-status"
export const POST_ADD_EDIT_SUBJECT = "/subject/add-edit"
export const POST_GET_SUBJECT_DETAIL = "/subject/detail"
export const POST_GET_SUBJECT_FORM_OPTION = "/subject/get-form-option"


//subject
export const POST_ALL_CHAPTER_LIST = process.env.REACT_APP_API_URL+"chapter/get-all-list"
export const DELETE_CHAPTER = "/chapter/delete"
export const POST_UPDATE_CHAPTER_STATUS = "/chapter/update-status"
export const POST_ADD_EDIT_CHAPTER = "/chapter/add-edit"
export const POST_GET_CHAPTER_DETAIL = "/chapter/detail"
export const POST_GET_CHAPTER_FORM_OPTION = "/chapter/get-form-option"

// common
export const POST_GET_CLASS_BY_BOARD_FORM_OPTION = "/com/get-class"
export const POST_GET_SUBJECT_BY_CLASS_FORM_OPTION = "/com/get-subject"
export const POST_GET_CHAPTER_BY_SUBJECT_FORM_OPTION = "/com/get-chapter"
export const POST_GET_USER_LIST = "/com/get-user"


//mock-tests
export const POST_ALL_MOCK_TESTS_LIST = process.env.REACT_APP_API_URL+"mock-tests/get-all-list"
export const DELETE_MOCK_TESTS = "/mock-tests/delete"
export const POST_UPDATE_MOCK_TESTS_STATUS = "/mock-tests/update-status"
export const POST_ADD_EDIT_MOCK_TESTS = "/mock-tests/add-edit"
export const POST_GET_MOCK_TESTS_DETAIL = "/mock-tests/detail"
export const POST_GET_MOCK_TESTS_FORM_OPTION = "/mock-tests/get-form-option"
export const POST_IMPORT_MCQ = "/mock-tests/import-mcq"
export const POST_GET_ALL_QUESTIONS = "/mock-tests/get-all-questions"
export const DELETE_QUESTIONS = "/mock-tests/delete-questions"
export const POST_ADD_EDIT_QUESTIONS = "/mock-tests/add-edit-question"


//students
export const POST_ALL_STUDENT_LIST = process.env.REACT_APP_API_URL+"student/get-all-list"
export const DELETE_STUDENT = "/student/delete"
export const POST_UPDATE_STUDENT_STATUS = "/student/update-status"

//agent
export const POST_AGENT_LIST = process.env.REACT_APP_API_URL+"agent/get-all-list"
export const DELETE_AGENT = "/agent/delete"
export const POST_UPDATE_AGENT_STATUS = "/agent/update-status"
export const POST_GET_AGENT_DETAIL = "/agent/detail"
export const POST_ADD_EDIT_AGENT = "/agent/add-edit"

// payment
export const POST_ALL_PAYMENT = process.env.REACT_APP_API_URL+"payment/get-all-list"
export const PAYMENT_PDF_URL = process.env.REACT_APP_API_URL.replace('api/v1/','') +"pdf/invoice?id="

//withdrawal
export const POST_GET_WITHDRAWAL_REQUEST = process.env.REACT_APP_API_URL+"withdrawal/get-withdrawal-request"
export const POST_UPDATE_WITHDRAWAL_STATUS = "/withdrawal/update-status"

export const POST_GET_COMMISSION_REPORT = process.env.REACT_APP_API_URL+"report/get-commission-request"
export const COMMISSION_REPORT_PDF_URL = process.env.REACT_APP_API_URL.replace('api/v1/','') +"pdf/commission-request"

//agent module
export const POST_AGENT_DASHBOARD = "/agent/dashboard"
export const POST_AGENT_UPDATE_PROFILE = "/agent/update-profile"
export const POST_AGENT_UPDATE_BANK_INFO = "/agent/update-bank-detaile"
export const POST_GET_AGENT_WITHDRAWAL_REQUEST = process.env.REACT_APP_API_URL+"agent/get-withdrawal-request"
export const POST_AGENT_WITHDRAWAL_REQUEST = "/agent/withdrawal-request"