import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post, get } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { POST_ADD_EDIT_CLASS, POST_GET_CLASS_DETAIL, POST_GET_CLASS_FORM_OPTION } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";

const UserRole = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const [title, setTitle] = useState('Add Class');
    const [boardList, setBoardList] = useState([]);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ 'classId': 0, 'boardId': '', 'className': '', 'classPrice': '' });
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                setTitle('Edit Class');
                var res_data = await post(POST_GET_CLASS_DETAIL, { 'classId': props.match.params.id }, {});
                if (res_data.status) {
                    setInputs(res_data.data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            get_form_option();
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])


    //get course form option
    const get_form_option = async () => {
        var res_data = await post(POST_GET_CLASS_FORM_OPTION, {});
        if (res_data.status) {
            setBoardList(res_data.data.board_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_CLASS, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/class");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }


    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>QGINNY | Class</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-6'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>

                                <div className="form-group mb-4">
                                    <label className="form-label text-capitalize">Select Board</label>
                                    <select name="boardId" className="form-control" value={form_inputs.boardId} onChange={handleInputChange}>
                                        <option value="">Select Board</option>
                                        {Object.entries(boardList).map(([key, board]) => (
                                            <option value={board.boardId } key={key}>{board.boardName}</option>
                                        ))}
                                    </select>
                                    {simpleValidator.current.message('Board', form_inputs.boardId, 'required')}
                                </div>
                                <div className="form-group mb-4">
                                    <label className="form-label text-capitalize">Class</label>
                                    <input name="className" value={form_inputs.className} onChange={handleInputChange} className="form-control" type="text" />
                                    {simpleValidator.current.message('name', form_inputs.className, 'required')}
                                </div>

                                <div className="form-group mb-4">
                                    <label className="form-label text-capitalize">Price</label>
                                    <input name="classPrice" value={form_inputs.classPrice} onChange={handleInputChange} className="form-control" type="text" />
                                    {simpleValidator.current.message('price', form_inputs.classPrice, 'required')}
                                </div>

                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                    <Link to="/class" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(UserRole)
