import React from 'react';
import Logo from './../assets/img/logo.png';
import { Link } from "react-router-dom";

import FeatherIcon from "feather-icons-react";
import { getUserType } from '../helpers/Helper';

export default function Header(props) {

    const handleLangChange = () => {
        props.onChangeHandleMenu();            
    }

  return (
    <div className='nav_bar'>
        <div className='nav_bar_left text-center'>
            <img alt="QGINNY" src={Logo} width='160px' />
        </div>
        <div className='nav_bar_right'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <a href='#' onClick={handleLangChange}><FeatherIcon icon="menu" className="mt-10" /></a>
                    </div>
                    <div className='col-sm-6 text-right'>
                        <div className='dropdown'>
                            <img alt="Remy Sharp" className='profile' src="/assets/img/avatar.png" width="40px" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <Link className="dropdown-item" to={(getUserType() == 'Admin' ? "/change-password" : "/agent/change-password")}>Change Password</Link>
                                <Link className="dropdown-item" to="/logout">Logout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}