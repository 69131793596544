import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom"
import { POST_GET_COMMISSION_REPORT, COMMISSION_REPORT_PDF_URL } from '../../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { getToken, uct_local, amountFormat,getUserId } from '../../../helpers/Helper';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";


const WithdrawalRequestList = props => {

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [form_inputs, setInputs] = useState({ startDate: '', endDate: '' });


    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = (status = "") => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_COMMISSION_REPORT+"?userId="+getUserId()+"&startDate="+form_inputs.startDate+"&endDate="+form_inputs.endDate,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "commissionId" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {uct_local(data.createdAt, 'DD, MMM YYYY')}
                            </>, td)
                        }
                    },
                    { "data": "agentCode" },
                    { "data": "userCode" },
                    { "data": "firstName" },
                    { "data": "info" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.commissionPre + '%'}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.transactionType == 'Credit' && <span className="text-success">{data.transactionType}</span>}
                                {data.transactionType != 'Credit' && <span className="text-danger">{data.transactionType}</span>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.transactionType == 'Credit' && <span className="text-success">{amountFormat(data.commission)}</span>}
                                {data.transactionType != 'Credit' && <span className="text-danger">-{amountFormat(data.commission)}</span>}
                            </>, td)
                        }
                    }
                ]
            });
        });
    }

    const handleInputChange = (event) => {
        if (event.target.name == 'startDate') {
            setStartDate(event.target.value);
            var date = new Date(event.target.value),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            var new_date = [date.getFullYear(), mnth, day].join("-");
            setInputs(inputs => ({ ...form_inputs, ['startDate']: new_date }));
        } else if (event.target.name == 'endDate') {
            setEndDate(event.target.value);
            var date = new Date(event.target.value),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            var new_date = [date.getFullYear(), mnth, day].join("-");
            setInputs(inputs => ({ ...form_inputs, ['endDate']: new_date }));
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    const exportPDF = () => {
        window.open(`${COMMISSION_REPORT_PDF_URL}?userId=${getUserId()}&fromDate=${form_inputs.startDate}&toDate=${form_inputs.endDate}`);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>QGINNY | Sales Ledger</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Sales Ledger</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 mb-5">
                                <div className="row mb-3">
                                    <div className="col-sm-2 mb-3">
                                        
                                    </div>
                                    <div className="col-sm-3 mb-3">
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={startDate}
                                            maxDate={new Date()}
                                            onChange={((value) => { handleInputChange({ target: { name: 'startDate', value: value } }) })}
                                            name="startDate"
                                            className="form-control"
                                            placeholderText="Select Date"
                                        />
                                    </div>
                                    <div className="col-sm-3 mb-3">
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={endDate}
                                            maxDate={new Date()}
                                            onChange={((value) => { handleInputChange({ target: { name: 'endDate', value: value } }) })}
                                            name="endDate"
                                            className="form-control"
                                            placeholderText="Select Date"
                                        />
                                    </div>
                                    <div className="col-sm-3 mb-3">
                                        <button type="button" onClick={get_list} className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white mr-2">Apply</button>
                                        <button type="button" onClick={exportPDF} className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white"> Export Pdf</button>
                                    </div>
                                </div>
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Date</td>
                                            <td>Agent Id</td>
                                            <td>Student Id</td>
                                            <td>Student Name</td>
                                            <td>Info</td>
                                            <td>Commission Pre</td>
                                            <td>Type</td>
                                            <td>Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default withRouter(WithdrawalRequestList)
