import React from 'react';
import { NavLink } from "react-router-dom";

import FeatherIcon from "feather-icons-react";
export default function Header() {

    return (
        <div className='left_nav_bar'>
            <ul className='sidebar-menu'>
                <li>
                    <NavLink exact to="/agent/dashboard" className="has-arrow">
                        <i className="fa fa-tachometer" style={{ 'fontSize': '19px', 'marginTop': '5px', 'marginRight': '8px' }} aria-hidden="true"></i>
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/agent/profile" className="has-arrow">
                        <FeatherIcon icon="users" width="22" />
                        <span>Profile</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/agent/bank" className="has-arrow">
                        <i className="fa fa-university" style={{ 'fontSize': '16px', 'marginTop': '7px', 'marginRight': '8px' }} aria-hidden="true"></i>
                        {/* <FeatherIcon icon="university" width="22" /> */}
                        <span>Bank Info</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/agent/withdrawal" className="has-arrow">
                        <i className="fa fa-inr" style={{ 'fontSize': '16px', 'marginTop': '7px', 'marginRight': '8px' }} aria-hidden="true"></i>
                        <span>Withdrawal Request</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/agent/commission-report" className="has-arrow">
                        <i className="fa fa-inr" style={{ 'fontSize': '16px', 'marginTop': '7px', 'marginRight': '8px' }} aria-hidden="true"></i>
                        <span>Sales Ledger</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    );
}