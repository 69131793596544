import React from 'react';
import { Route, Redirect } from 'react-router';
import { is_login, getUserType } from '../helpers/Helper'

const UserRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            (is_login()) ? (
                (getUserType() == 'Agent') ? (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                ) : <Redirect to="/" />

            ) : <Redirect to="/login" />
        )}

    />
);

export default UserRoute;
