import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { POST_ADD_EDIT_AGENT, POST_GET_AGENT_DETAIL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";

const UserRole = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const [title, setTitle] = useState('Add Agent');
    const [boardList, setBoardList] = useState([]);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({
        userId : 0,
        firstName: '',
        email: '',
        mobileNo: '',
        password: '',
        address: '',
        userType: '',
        occupation: '',
        institutionName: '',
        institutionAddress: '',
        userCommission: '',
        userStatus: '',
        panNo: '',
        aadharNo: '',
        userDiscount : 0,
    });
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                setTitle('Edit Agent');
                var res_data = await post(POST_GET_AGENT_DETAIL, { 'userId': props.match.params.id }, {});
                if (res_data.status) {
                    setInputs(res_data.data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_AGENT, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/agent");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if(event.target.name == 'panNo'){
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value.toUpperCase() }));
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }


    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>QGINNY | Class</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Full Name</label>
                                                <input name="firstName" value={form_inputs.firstName} onChange={handleInputChange} className="form-control" placeholder="Enter Your Full Name" type="text" />
                                                {simpleValidator.current.message('full name', form_inputs.firstName, 'required')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Mobile Number</label>
                                                <input name="mobileNo" maxLength={10} value={form_inputs.mobileNo} onChange={handleInputChange} className="form-control" placeholder="Enter Your Mobile Number" type="text" />
                                                {simpleValidator.current.message('mobile number', form_inputs.mobileNo, 'required|numeric|min:10|max:10')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Email Address</label>
                                                <input name="email" value={form_inputs.email} onChange={handleInputChange} className="form-control" placeholder="Enter Your Email Address" type="text" />
                                                {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Password</label>
                                                <input name="password" value={form_inputs.password} onChange={handleInputChange} type="password" className="form-control" placeholder="Enter your Password" />
                                            </div>
                                            <div className='col-sm-12 mb-3'>
                                                <label>Full Address</label>
                                                <textarea name="address" rows={4} value={form_inputs.address} onChange={handleInputChange} className="form-control" placeholder="Enter your Full Address (e.g Address, City, District, PIN, State)" />
                                                {simpleValidator.current.message('address', form_inputs.address, 'required')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Commission</label>
                                                <input name="userCommission" value={form_inputs.userCommission} onChange={handleInputChange} className="form-control" placeholder="Enter Your Commission" type="text" />
                                                {simpleValidator.current.message('commission', form_inputs.userCommission, 'required|numeric')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>User Discount</label>
                                                <input name="userDiscount" value={form_inputs.userDiscount} onChange={handleInputChange} className="form-control" placeholder="Enter Your User Discount" type="text" />
                                                {simpleValidator.current.message('discount', form_inputs.userDiscount, 'required|numeric')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Pan Number</label>
                                                <input name="panNo" value={form_inputs.panNo} onChange={handleInputChange} className="form-control" placeholder="Enter Your PAN Number" type="text" />
                                                {simpleValidator.current.message('pan number', form_inputs.panNo, (form_inputs.panNo)? 'required|min:10|max:10' : '')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Aadhar Number</label>
                                                <input name="aadharNo" maxLength={12} value={form_inputs.aadharNo} onChange={handleInputChange} className="form-control" placeholder="Enter Your Aadhar Number" type="text" />
                                                {simpleValidator.current.message('mobile number', form_inputs.aadharNo, (form_inputs.aadharNo)? 'required|numeric|min:12|max:12' : '')}
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <label>Status</label>
                                                <select name="userStatus" value={form_inputs.userStatus} onChange={handleInputChange} className='form-control'>
                                                    <option value="">Select Status</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Deactive">Deactive</option>
                                                </select>
                                                {simpleValidator.current.message('status', form_inputs.userStatus, 'required')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <div className='col-sm-12 mb-3'>
                                                <label>Status</label>
                                                <select name="userType" value={form_inputs.userType} onChange={handleInputChange} className='form-control'>
                                                    <option value="">Select Status</option>
                                                    <option value="Individual">Individual</option>
                                                    <option value="Institution">Institution</option>
                                                </select>
                                                {simpleValidator.current.message('Status', form_inputs.userType, 'required')}
                                            </div>
                                            {form_inputs.userType == 'Individual' && <>
                                                <div className='col-sm-12 mb-3'>
                                                    <label>Occupation</label>
                                                    <select name="occupation" value={form_inputs.occupation} onChange={handleInputChange} className='form-control'>
                                                        <option value="">Select Occupation</option>
                                                        <option value="Student">Student</option>
                                                        <option value="Teacher">Teacher</option>
                                                        <option value="Shop Owner">Shop Owner</option>
                                                        <option value="Self Emplyoed">Self Emplyoed</option>
                                                        <option value="Others">Others</option>
                                                    </select>
                                                </div>
                                            </>}
                                            {form_inputs.userType == 'Institution' && <>
                                                <div className='col-sm-12 mb-3'>
                                                    <label>Name Of The Institution</label>
                                                    <input name="institutionName" value={form_inputs.institutionName} onChange={handleInputChange} className="form-control" placeholder="Enter Your Institution Name" type="text" />
                                                </div>
                                                <div className='col-sm-12 mb-3'>
                                                    <label>Address</label>
                                                    <textarea name="institutionAddress" rows={4} value={form_inputs.institutionAddress} onChange={handleInputChange} className="form-control" placeholder="Enter your Full Address (e.g Address, City, District, PIN, State)" />
                                                </div>
                                            </>}

                                        </div>
                                    </div>
                                </div>

                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                    <Link to="/agent" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(UserRole)
