import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import {POST_ADD_EDIT_QUESTIONS} from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const UserRole = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_input, setInputs] = useState({
        'questionsId': 0,
        'mockTestsId': 0,
        'question': 'sadasd',
        'answer': '',
        'answerExplanation': '',
        'option1': '',
        'option2': '',
        'option3': '',
        'option4': '',
        'marks': ''
    });
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            if(props.question.questionsId){
                setInputs({
                    'questionsId': props.question.questionsId,
                    'mockTestsId': props.mockTestsId,
                    'question': props.question.question,
                    'answer': props.question.answer,
                    'answerExplanation': props.question.answerExplanation,
                    'option1': props.question.option1,
                    'option2': props.question.option2,
                    'option3': props.question.option3,
                    'option4': props.question.option4,
                    'marks': props.question.marks
                });
            }else{
                setInputs({
                    'questionsId': 0,
                    'mockTestsId': 0,
                    'question': '',
                    'answer': '',
                    'answerExplanation': '',
                    'option1': '',
                    'option2': '',
                    'option3': '',
                    'option4': '',
                    'marks': ''
                });
            }
            setLoader(1);
        }
        get_detaile();
    }, [props])



    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            form_input.mockTestsId = props.mockTestsId;
            var res_data = await post(POST_ADD_EDIT_QUESTIONS, form_input, {});
            if (res_data.status) {
                toast(res_data.message);
                props.call_back();
                window.$('#QuestionAddEditModal').modal('hide');
                if(!props.question.questionsId){
                    setInputs({
                        'questionsId': 0,
                        'mockTestsId': 0,
                        'question': '',
                        'answer': '',
                        'answerExplanation': '',
                        'option1': '',
                        'option2': '',
                        'option3': '',
                        'option4': '',
                        'marks': ''
                    });
                }
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        setInputs(prevInput => ({ ...prevInput, [event.target.name]: event.target.value }));
    }





    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>QGINNY | Mock Test</title>
            </Helmet>



            {/* QuestionViewModal */}
            <div className="modal fade" id="QuestionAddEditModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title m-0" id="exampleModalLabel">Add Edit Question</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className="form-group mb-4 col-sm-12">
                                        <label className="form-label text-capitalize"><b>Q : </b>Question</label>
                                        <ReactQuill theme="snow" value={form_input.question} onChange={(value) => { handleInputChange({ target: { name: 'question', value: value } }) }} />
                                        {simpleValidator.current.message('question', form_input.question, 'required')}
                                    </div>
                                    <div className="form-group mb-4 col-sm-12">
                                        <label className="form-label text-capitalize"><b>A : </b>Option</label>
                                        <ReactQuill theme="snow" value={form_input.option1} onChange={(value) => { handleInputChange({ target: { name: 'option1', value: value } }) }} />
                                        {simpleValidator.current.message('option a', form_input.option1, 'required')}
                                    </div>
                                    <div className="form-group mb-4 col-sm-12">
                                        <label className="form-label text-capitalize"><b>B : </b>Option</label>
                                        <ReactQuill theme="snow" value={form_input.option2} onChange={(value) => { handleInputChange({ target: { name: 'option2', value: value } }) }} />
                                        {simpleValidator.current.message('option b', form_input.option2, 'required')}
                                    </div>
                                    <div className="form-group mb-4 col-sm-12">
                                        <label className="form-label text-capitalize"><b>C : </b>Option</label>
                                        <ReactQuill theme="snow" value={form_input.option3} onChange={(value) => { handleInputChange({ target: { name: 'option3', value: value } }) }} />
                                        {simpleValidator.current.message('option c', form_input.option3, 'required')}
                                    </div>
                                    <div className="form-group mb-4 col-sm-12">
                                        <label className="form-label text-capitalize"><b>D : </b>Option</label>
                                        <ReactQuill theme="snow" value={form_input.option4} onChange={(value) => { handleInputChange({ target: { name: 'option4', value: value } }) }} />
                                        {simpleValidator.current.message('option d', form_input.option4, 'required')}
                                    </div>
                                    <div className="form-group mb-4 col-sm-12">
                                        <label className="form-label text-capitalize">Answer Description</label>
                                        <ReactQuill theme="snow" value={form_input.answerExplanation} onChange={(value) => { handleInputChange({ target: { name: 'answerExplanation', value: value } }) }} />
                                    </div>
                                    <div className="form-group mb-4 col-sm-6">
                                        <label className="form-label text-capitalize">Answer</label>
                                        <select name="answer" className="form-control" value={form_input.answer} onChange={handleInputChange}>
                                            <option value="">Select Answer</option>
                                            <option value="option1" >Option A</option>
                                            <option value="option2" >Option B</option>
                                            <option value="option3" >Option C</option>
                                            <option value="option4" >Option D</option>
                                        </select>
                                        {simpleValidator.current.message('answer', form_input.answer, 'required')}
                                    </div>
                                    <div className="form-group mb-4 col-sm-6">
                                        <label className="form-label text-capitalize">Marks</label>
                                        <input name="marks" value={form_input.marks} onChange={handleInputChange} className="form-control" type="text" />
                                        {simpleValidator.current.message('marks', form_input.marks, 'required')}
                                    </div>
                                </div>
                                <div className="text-center mt-4 col-sm-12">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                    <button data-dismiss="modal" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
export default withRouter(UserRole)
