import React, { useState, useRef } from 'react';
import Logo from './../../assets/img/logo.png';
import { withRouter } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import { POST_AGENT_SIGN_UP } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

const AgentSignUp = props => {

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [SuccessSignUp, SetSuccessSignUp] = useState(false);
    const [form_inputs, setInputs] = useState({
        userId : 0,
        firstName: '',
        email: '',
        mobileNo: '',
        password: '',
        address: '',
        userType: '',
        occupation: '',
        institutionName: '',
        institutionAddress: '',
    });
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_AGENT_SIGN_UP, form_inputs, {});
            if (res_data.status) {
                SetSuccessSignUp(true);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <>
            <div className='auth_page'>
                <div className='left_bar'>
                    <img alt="QGINNY" src={Logo} />
                </div>
                <div className='right_bar'>
                    <div className='p-3'>
                        {SuccessSignUp && <div style={{ "maxWidth": "450px", "margin": "0px auto" }}>
                            <div className='text-center pt-5'>
                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="80px" height="80px"><g fill="#069852" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" ><g transform="scale(5.12,5.12)"><path d="M25,2c-12.69047,0 -23,10.30953 -23,23c0,12.69047 10.30953,23 23,23c12.69047,0 23,-10.30953 23,-23c0,-12.69047 -10.30953,-23 -23,-23zM25,4c11.60953,0 21,9.39047 21,21c0,11.60953 -9.39047,21 -21,21c-11.60953,0 -21,-9.39047 -21,-21c0,-11.60953 9.39047,-21 21,-21zM34.98828,14.98828c-0.3299,0.0065 -0.63536,0.17531 -0.81641,0.45117l-10.20117,15.03711l-7.29102,-6.76562c-0.26069,-0.25084 -0.63652,-0.34135 -0.98281,-0.23667c-0.3463,0.10468 -0.60907,0.38821 -0.68715,0.74145c-0.07809,0.35324 0.04068,0.72112 0.31059,0.96201l8.99609,8.34766l11.51172,-16.96484c0.2153,-0.3085 0.23926,-0.71173 0.06201,-1.04356c-0.17725,-0.33183 -0.52573,-0.53612 -0.90186,-0.5287z"></path></g></g></svg>
                            </div>
                            <h4 className='text-center text-success mb-5'>Success</h4>
                            <p>Congratulation, your account has been successfully created.</p>
                            <p>Before you can login, your account must be manually activated by an administrator.</p>
                            <p>Please wait, this can take some time.</p>
                        </div>}
                        
                        {!SuccessSignUp && <div>
                            <h4 >Welcome Back !</h4>
                            <p className="text-muted mt-2 mb-5">Sign Up in to continue to QGINNY.</p>
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-sm-6 mb-3'>
                                        <label>Full Name</label>
                                        <input name="firstName" value={form_inputs.firstName} onChange={handleInputChange} className="form-control" placeholder="Enter Your Full Name" type="text" />
                                        {simpleValidator.current.message('full name', form_inputs.firstName, 'required')}
                                    </div>
                                    <div className='col-sm-6 mb-3'>
                                        <label>Mobile Number</label>
                                        <input name="mobileNo" maxLength={10} value={form_inputs.mobileNo} onChange={handleInputChange} className="form-control" placeholder="Enter Your Mobile Number" type="text" />
                                        {simpleValidator.current.message('mobile number', form_inputs.mobileNo, 'required|numeric|min:10|max:10')}
                                    </div>
                                    <div className='col-sm-6 mb-3'>
                                        <label>Email Address</label>
                                        <input name="email" value={form_inputs.email} onChange={handleInputChange} className="form-control" placeholder="Enter Your Email Address" type="text" />
                                        {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                    </div>
                                    <div className='col-sm-6 mb-3'>
                                        <label>Password</label>
                                        <input name="password" value={form_inputs.password} onChange={handleInputChange} type="password" className="form-control" placeholder="Enter your Password" />
                                        {simpleValidator.current.message('password', form_inputs.password, 'required')}
                                    </div>
                                    <div className='col-sm-12 mb-3'>
                                        <label>Full Address</label>
                                        <textarea name="address" rows={4} value={form_inputs.address} onChange={handleInputChange} className="form-control" placeholder="Enter your Full Address (e.g Address, City, District, PIN, State)" />
                                        {simpleValidator.current.message('address', form_inputs.address, 'required')}
                                    </div>
                                    <div className='col-sm-12 mb-3'>
                                        <label>Status</label>
                                        <select name="userType" value={form_inputs.userType} onChange={handleInputChange} className='form-control'>
                                            <option value="">Select Status</option>
                                            <option value="Individual">Individual</option>
                                            <option value="Institution">Institution</option>
                                        </select>
                                        {simpleValidator.current.message('Status', form_inputs.userType, 'required')}
                                    </div>
                                    <div className='col-sm-12 mb-3'>
                                        {form_inputs.userType == 'Individual' && <>
                                            <label>Occupation</label>
                                            <select name="occupation" value={form_inputs.occupation} onChange={handleInputChange} className='form-control'>
                                                <option value="">Select Occupation</option>
                                                <option value="Student">Student</option>
                                                <option value="Teacher">Teacher</option>
                                                <option value="Shop Owner">Shop Owner</option>
                                                <option value="Self Emplyoed">Self Emplyoed</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </>}
                                        {simpleValidator.current.message('occupation', form_inputs.occupation, (form_inputs.userType == 'Individual') ? 'required' : '')}
                                    </div>
                                    <div className='col-sm-12 mb-3'>
                                        {form_inputs.userType == 'Institution' && <>
                                            <label>Name Of The Institution</label>
                                            <input name="institutionName" value={form_inputs.institutionName} onChange={handleInputChange} className="form-control" placeholder="Enter Your Institution Name" type="text" />
                                        </>}
                                        {simpleValidator.current.message('institution', form_inputs.institutionName, (form_inputs.userType == 'Institution') ? 'required' : '')}
                                    </div>
                                    <div className='col-sm-12 mb-3'>
                                        {form_inputs.userType == 'Institution' && <>
                                            <label>Address</label>
                                            <textarea name="institutionAddress" rows={4} value={form_inputs.institutionAddress} onChange={handleInputChange} className="form-control" placeholder="Enter your Full Address (e.g Address, City, District, PIN, State)" />
                                        </>}
                                        {simpleValidator.current.message('address', form_inputs.institutionAddress, (form_inputs.userType == 'Institution') ? 'required' : '')}
                                    </div>
                                </div>


                                <div className="form-group mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className="btn btn-primary c-btn-primary"> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Sign Up Securely </button>
                                </div>
                            </form>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(AgentSignUp)
