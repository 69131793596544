import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom"
import { POST_GET_WITHDRAWAL_REQUEST, POST_UPDATE_WITHDRAWAL_STATUS } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { getToken, uct_local, amountFormat } from '../../helpers/Helper';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { post } from "../../helpers/api_helper";
import { toast } from "react-toastify";


const WithdrawalRequestList = props => {

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [Info, SetInfo] = useState({});

    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = (status = "") => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_WITHDRAWAL_REQUEST,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [7, 8] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "withdrawalRequestId" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {uct_local(data.createdAt, 'DD, MMM YYYY')}
                            </>, td)
                        }
                    },
                    { "data": "userCode" },
                    { "data": "accoutHolderName" },
                    { "data": "bankAccountNo" },
                    { "data": "bankName" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {amountFormat(data.amount)}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.withdrawalRequestStatus === 'Pending' && <button type="button" className="border-0 badge badge-info pt-1 pb-1"  >{data.withdrawalRequestStatus}</button>}
                                {data.withdrawalRequestStatus === 'Completed' && <button type="button" className="border-0 badge badge-success pt-1 pb-1"  >{data.withdrawalRequestStatus}</button>}
                                {data.withdrawalRequestStatus === 'Cancelled' && <button type="button" className="border-0 badge badge-danger pt-1 pb-1" >{data.withdrawalRequestStatus}</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.withdrawalRequestStatus === 'Pending' && <>
                                    <button type="button" className="border-0 badge badge-success pt-1 pb-1 mr-2 pr-2 pl-2 pointer"  onClick={() => { SetInfo(data); }} data-toggle="modal" data-target="#PayModal" >Pay</button>
                                    <button type="button" className="border-0 badge badge-danger pt-1 pb-1 pr-2 pl-2 pointer" onClick={() => { SetInfo(data); }} data-toggle="modal" data-target="#CancelModal">Cancel</button>

                                </>}
                            </>, td)
                        }
                    }
                ]
            });
        });
    }

     // remove user role
    const update = async (status = 'Cancelled') => {
        SetButtonDisabled(true);
        var res_data = await post(POST_UPDATE_WITHDRAWAL_STATUS ,{id : Info.withdrawalRequestId,status  : status});
        if (res_data.status) {
            toast(res_data.message);
            get_list();
            window.$('#PayModal').modal('hide');
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>QGINNY | Withdrawal Request List</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Withdrawal Request List</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 mb-5">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Date</td>
                                            <td>User Id</td>
                                            <td>Accout Holder Name</td>
                                            <td>Account Number</td>
                                            <td>Bank Name</td>
                                            <td>Amount</td>
                                            <td>Status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="CancelModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Cancel</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to cancel this record?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">No</button>
                            <button onClick={update.bind(this,'cancel')} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="PayModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Payout</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <p className="mb-0"><b>Accout Holder Name</b></p>
                                    <p>{Info.accoutHolderName}</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><b>Bank Account Number</b></p>
                                    <p>{Info.bankAccountNo}</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><b>Bank Name</b></p>
                                    <p>{Info.bankName}</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><b>IFSC Code</b></p>
                                    <p>{Info.bankIFSCCode}</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><b>Amount</b></p>
                                    <p>{amountFormat(Info.amount)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button disabled={ButtonDisabled} type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">Cancel</button>
                            <button disabled={ButtonDisabled} onClick={update.bind(this,'pay')} className="btn btn-sm btn-primary c-btn-primary">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Pay</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(WithdrawalRequestList)
