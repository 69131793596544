import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import ReactDOM from 'react-dom';
import { POST_ALL_PAYMENT, PAYMENT_PDF_URL } from '../../helpers/url_helper';
import { getToken, amountFormat, uct_local } from '../../helpers/Helper';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";


const Payment = props => {

    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_ALL_PAYMENT,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [7] }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "invoiceId" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {uct_local(data.createdAt,'DD, MMM YYYY')}
                            </>, td)
                        }
                    },
                    { "data": "transactionId" },
                    { "data": "firstName" },
                    { "data": "email" },
                    { "data": "mobileNo" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(amountFormat(data.totalAmount), td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <a href={PAYMENT_PDF_URL+data.pdfId} target="_blank"><i className="fa fa-file-pdf-o" style={{ 'fontSize': '16px' }} aria-hidden="true"></i></a>
                            </>, td)
                        }
                    }
                ]
            });
        });
    }

 
    return (
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Payment History</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 ">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Invoice</td>
                                            <td>Date</td>
                                            <td>Transaction Id</td>
                                            <td>Name</td>
                                            <td>Email</td>
                                            <td>Phone Number</td>
                                            <td>Amount</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default withRouter(Payment);
