import { useState, useRef } from 'react';
import readXlsxFile from 'read-excel-file'
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { post } from '../helpers/api_helper';
import { POST_IMPORT_MCQ } from '../helpers/url_helper';

export default function UploadProduct(props) {

    const [optionList, setOptionList] = useState([]);
    const [ProductList, setProductList] = useState([]);
    const [ShowTable, setShowTable] = useState(false);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({
        question: '',
        answer: '',
        answerExplanation: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        marks: '',
    });

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'file') {
            readXlsxFile(event.target.files[0]).then(function (rows) {
                // var filteredData = rows.map((row) => row.filter((cell) => cell !== null));
                var filteredData = rows.filter(a => !a.every(ax => a == undefined));

                if (filteredData.length > 0) {
                    setOptionList(filteredData[0]);
                    filteredData.splice(0, 1);
                    setProductList(filteredData);

                    setInputs({
                        question: (filteredData[0].length > 0)? 0 :'',
                        answer: (filteredData[0].length > 2)? 2 :'',
                        answerExplanation: (filteredData[0].length > 3)? 3 :'',
                        option1: (filteredData[0].length > 5)? 5 :'',
                        option2: (filteredData[0].length > 7)? 7 :'',
                        option3: (filteredData[0].length > 9)? 9 :'',
                        option4: (filteredData[0].length > 11)? 11 :'',
                        marks: (filteredData[0].length > 13)? 13 :'',
                    });

                    setShowTable(false);
                    window.$('#UploadProductModal').modal('hide');
                    window.$('#UploadProductModal1').modal('show');
                } else {
                    toast.error('no any data');
                }
            })
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            setShowTable(true);
        }
    }

    const Import = async () => {
        SetButtonDisabled(true);
        if(ProductList.length > 0){
            form_inputs.mockTestsId = props.mockTestsId;
            form_inputs.mcqList = ProductList;
            var res_data = await post(POST_IMPORT_MCQ, form_inputs);
            if(res_data.status){
                props.call_back();
                window.$('#UploadProductModal1').modal('hide');
                window.$('#UploadMCQModal').modal('hide');
                toast(res_data.message);
            }else{
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }else{
            toast.error('No Data Found!');
        }
    }

    const check_row = (value) => {
        if(!value[form_inputs.question]){
            return 'table-danger'
        }
        if(value[form_inputs.answer] != 'option1' && value[form_inputs.answer] != 'option2' && value[form_inputs.answer] != 'option3' && value[form_inputs.answer] != 'option4'){
            return 'table-danger'
        }
    }
    return (<>
        <div className="modal fade" id="UploadMCQModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h6 className="modal-title mt-0" id="exampleModalLabel"></h6>
                        <svg className='pointer' data-dismiss="modal" aria-label="Close" width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.9993 2.66666C8.65268 2.66666 2.66602 8.65332 2.66602 16C2.66602 23.3467 8.65268 29.3333 15.9993 29.3333C23.346 29.3333 29.3327 23.3467 29.3327 16C29.3327 8.65332 23.346 2.66666 15.9993 2.66666ZM20.4793 19.0667C20.866 19.4533 20.866 20.0933 20.4793 20.48C20.2793 20.68 20.026 20.7733 19.7727 20.7733C19.5193 20.7733 19.266 20.68 19.066 20.48L15.9993 17.4133L12.9327 20.48C12.7327 20.68 12.4793 20.7733 12.226 20.7733C11.9727 20.7733 11.7193 20.68 11.5193 20.48C11.1327 20.0933 11.1327 19.4533 11.5193 19.0667L14.586 16L11.5193 12.9333C11.1327 12.5467 11.1327 11.9067 11.5193 11.52C11.906 11.1333 12.546 11.1333 12.9327 11.52L15.9993 14.5867L19.066 11.52C19.4527 11.1333 20.0927 11.1333 20.4793 11.52C20.866 11.9067 20.866 12.5467 20.4793 12.9333L17.4127 16L20.4793 19.0667Z" fill="black" />
                        </svg>
                    </div>
                    <div className="modal-body">
                        <div className='row mb-4'>
                            <div className='col-sm-12 '>
                                <div className='text-center border-dashed rounded pointer'>
                                    <label for='upload_file' className='pt-5 pb-4 pointer'>
                                        <span className='btn btn-md btn-secondary mb-3 pl-4 pr-4'>Upload</span>
                                        <h6 className='mb-0'>upload a CSV files</h6>
                                        <p><small>(.CSV, .Xlsx File types are supported)</small></p>
                                    </label>
                                    <input type='file' name='file' onChange={handleInputChange} id="upload_file" className='d-none' accept='.xlsx, .xls, .csv' />
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-sm-12 text-center'>
                                <a href='/sample.xlsx'>Download Sample File</a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="modal-footer border-0">
                        <button type="button" className="btn btn-sm btn-defalt pl-3 pr-3" data-dismiss="modal">No</button>
                        <button data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary pl-3 pr-3">Yes</button>
                    </div> */}
                </div>
            </div>
        </div>

        <div className="modal fade" id="UploadProductModal1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": '1100px' }}>
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h6 className="modal-title mt-0" id="exampleModalLabel"></h6>
                        <svg className='pointer' data-dismiss="modal" aria-label="Close" width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.9993 2.66666C8.65268 2.66666 2.66602 8.65332 2.66602 16C2.66602 23.3467 8.65268 29.3333 15.9993 29.3333C23.346 29.3333 29.3327 23.3467 29.3327 16C29.3327 8.65332 23.346 2.66666 15.9993 2.66666ZM20.4793 19.0667C20.866 19.4533 20.866 20.0933 20.4793 20.48C20.2793 20.68 20.026 20.7733 19.7727 20.7733C19.5193 20.7733 19.266 20.68 19.066 20.48L15.9993 17.4133L12.9327 20.48C12.7327 20.68 12.4793 20.7733 12.226 20.7733C11.9727 20.7733 11.7193 20.68 11.5193 20.48C11.1327 20.0933 11.1327 19.4533 11.5193 19.0667L14.586 16L11.5193 12.9333C11.1327 12.5467 11.1327 11.9067 11.5193 11.52C11.906 11.1333 12.546 11.1333 12.9327 11.52L15.9993 14.5867L19.066 11.52C19.4527 11.1333 20.0927 11.1333 20.4793 11.52C20.866 11.9067 20.866 12.5467 20.4793 12.9333L17.4127 16L20.4793 19.0667Z" fill="black" />
                        </svg>
                    </div>

                    {!ShowTable && <div className="modal-body">
                        <form className="av-invalid" onSubmit={handleSubmit}>
                            <div className='row mb-4'>
                                <div className='col-sm-12 text-center'>
                                    <h5>Match columns to your board</h5>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-2'></div>
                                <div className='col-sm-8'>
                                    <div className='row mb-3'>
                                        <div className='col-6'>
                                            <h6><b>File Columns </b></h6>
                                        </div>
                                        <div className='col-6'>
                                            <h6><b>Board Columns </b></h6>
                                        </div>
                                    </div>

                                    <div className='row mb-3'>
                                        <div className='col-6'>
                                            <label className='form-label mt-2'>Question</label>
                                        </div>
                                        <div className='col-6'>
                                            <select className="form-control" name='question' onChange={handleInputChange} value={form_inputs.question}>
                                                <option value=""> Select Column</option>
                                                {optionList && Object.entries(optionList).map(([key, value]) => (
                                                    <option key={key} value={key}>{value}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('question', form_inputs.question, 'required')}
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-6'>
                                            <label className='form-label mt-2'>Answer</label>
                                        </div>
                                        <div className='col-6'>
                                            <select className="form-control" name='answer' onChange={handleInputChange} value={form_inputs.answer}>
                                                <option value=""> Select Column</option>
                                                {optionList && Object.entries(optionList).map(([key, value]) => (
                                                    <option key={key} value={key}>{value}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('answer', form_inputs.answer, 'required')}
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-6'>
                                            <label className='form-label mt-2'>Answer Explanation</label>
                                        </div>
                                        <div className='col-6'>
                                            <select className="form-control" name='answerExplanation' onChange={handleInputChange} value={form_inputs.answerExplanation}>
                                                <option value=""> Select Column</option>
                                                {optionList && Object.entries(optionList).map(([key, value]) => (
                                                    <option key={key} value={key}>{value}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('answer explanation', form_inputs.answerExplanation, 'required')}
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-6'>
                                            <label className='form-label mt-2'>Option 1</label>
                                        </div>
                                        <div className='col-6'>
                                            <select className="form-control" name='option1' onChange={handleInputChange} value={form_inputs.option1}>
                                                <option value=""> Select Column</option>
                                                {optionList && Object.entries(optionList).map(([key, value]) => (
                                                    <option key={key} value={key}>{value}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('option 1', form_inputs.option1, 'required')}
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-6'>
                                            <label className='form-label mt-2'>Option 2</label>
                                        </div>
                                        <div className='col-6'>
                                            <select className="form-control" name='option2' onChange={handleInputChange} value={form_inputs.option2}>
                                                <option value=""> Select Column</option>
                                                {optionList && Object.entries(optionList).map(([key, value]) => (
                                                    <option key={key} value={key}>{value}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('option 2', form_inputs.option2, 'required')}
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-6'>
                                            <label className='form-label mt-2'>Option 3</label>
                                        </div>
                                        <div className='col-6'>
                                            <select className="form-control" name='option3' onChange={handleInputChange} value={form_inputs.option3}>
                                                <option value=""> Select Column</option>
                                                {optionList && Object.entries(optionList).map(([key, value]) => (
                                                    <option key={key} value={key}>{value}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('option 3', form_inputs.option3, 'required')}
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-6'>
                                            <label className='form-label mt-2'>Option 4</label>
                                        </div>
                                        <div className='col-6'>
                                            <select className="form-control" name='option4' onChange={handleInputChange} value={form_inputs.option4}>
                                                <option value=""> Select Column</option>
                                                {optionList && Object.entries(optionList).map(([key, value]) => (
                                                    <option key={key} value={key}>{value}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('option 4', form_inputs.option4, 'required')}
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-6'>
                                            <label className='form-label mt-2'>Marks</label>
                                        </div>
                                        <div className='col-6'>
                                            <select className="form-control" name='marks' onChange={handleInputChange} value={form_inputs.marks}>
                                                <option value=""> Select Column</option>
                                                {optionList && Object.entries(optionList).map(([key, value]) => (
                                                    <option key={key} value={key}>{value}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('marks', form_inputs.marks, 'required')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-6'>
                                    <button type="button" className="btn btn-sm btn-defalt pl-3 pr-3" onClick={() => {
                                        window.$('#UploadProductModal1').modal('hide');
                                        window.$('#UploadProductModal').modal('show');
                                    }}>Back</button>
                                </div>
                                <div className='col-6 text-right'>
                                    <button type='submit' className="btn btn-sm btn-primary c-btn-primary pl-3 pr-3">Next</button>
                                </div>
                            </div>
                        </form>
                    </div>}

                    {ShowTable && <div className="modal-body">
                        <div className='row mb-4'>
                            <div className='col-sm-12 text-center'>
                                <h5>Here are the column we’re importing to your board</h5>
                                <p className='mb-0'>Make siyor answer column only allowed values in <b>(option1 , option2 , option3 , option4)</b></p>
                                <p className='mb-0'>Red color highlight row is some missing details please check.</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='col-sm-12' >
                                    <div className='table-responsive'>
                                        <table className='table table-sm '>
                                            <thead>
                                                <tr>
                                                    <th>Sr.</th>
                                                    <th>Question</th>
                                                    <th>Answer</th>
                                                    <th>Answer Explanation</th>
                                                    <th>Option 1</th>
                                                    <th>Option 2</th>
                                                    <th>Option 3</th>
                                                    <th>Option 4</th>
                                                    <th>Marks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ProductList && Object.entries(ProductList).map(([key, value]) => (
                                                    <tr key={key} className={check_row(value)}>
                                                        <td>{++key} {check_row.bind(this,value)} </td>
                                                        <td className='text-left'>{value[form_inputs.question]}</td>
                                                        <td className='text-left'>{value[form_inputs.answer]}</td>
                                                        <td className='text-left'>{value[form_inputs.answerExplanation]}</td>
                                                        <td className='text-left'>{value[form_inputs.option1]}</td>
                                                        <td className='text-left'>{value[form_inputs.option2]}</td>
                                                        <td className='text-left'>{value[form_inputs.option3]}</td>
                                                        <td className='text-left'>{value[form_inputs.option4]}</td>
                                                        <td className='text-left'>{value[form_inputs.marks]}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-6'>
                                <button type="button" className="btn btn-sm btn-defalt pl-3 pr-3" onClick={() => {
                                    setShowTable(false);
                                }}>Back</button>
                            </div>
                            <div className='col-6 text-right'>
                                <button type='button' onClick={Import} disabled={ButtonDisabled} className="btn btn-sm btn-primary c-btn-primary pl-3 pr-3">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Import</button>
                            </div>
                        </div>
                    </div>}

                </div>
            </div>
        </div>

    </>);
}