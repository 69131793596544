import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import { post } from '../../helpers/api_helper';
import { GET_DASHBOARD_DATA } from '../../helpers/url_helper';
import Loader from './../../component/Loader';
import { amountFormat } from '../../helpers/Helper';


const Dashboard = props => {

    const [HomeData, SetHomeData] = useState({});
    const [isLoader, setLoader] = useState(0);

    useEffect(async () => {
        setLoader(0);
        var res_data = await post(GET_DASHBOARD_DATA, {});
        if (res_data.status) {
            SetHomeData(res_data.data);
            setLoader(1);
        } else {
            toast.error(res_data.message);
            setLoader(1);
        }
    }, [])


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>QGINNY | Dashboard</title>
            </Helmet>
            {!isLoader && <Loader />}
            <div className='row'>
                <div className='col-sm-12'>
                    <h5>Welcome To Qginny</h5>
                </div>
            </div>
            <div className='row mt-15'>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <Link to="/students">
                        <div className='card'>
                            <div className="card-body text-center">
                                <h6>Total Student</h6>
                                <h6>{HomeData.totalStudent}</h6>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <Link to="/agent">
                        <div className='card'>
                            <div className="card-body text-center">
                                <h6>Total Agent</h6>
                                <h6>{HomeData.totalAgent}</h6>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6>Total Payment</h6>
                            <h6>{amountFormat(HomeData.totalPayment)}</h6>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6>Total Commission</h6>
                            <h6>{amountFormat(HomeData.totalCommission)}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(Dashboard);
